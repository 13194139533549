<template>
  <div class="login-page">
    <form>
      <input placeholder="jméno" v-model="name" />
      <input placeholder="heslo" type="password" v-model="password" />
      <input type="submit" class="submit" value="přihlásit" @click.prevent="login()" />
    </form>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      name: '',
      password: '',
    }
  },
  methods: {
    async login() {
      try {
        const { data } = await axios.post(
          '/adminapi/login',
          { name: this.name, password: this.password },
          { timeout: 20 * 60 * 1000 }
        )
        sessionStorage.setItem('token', data)
      } catch (e) {
        if (!e.response) {
          console.log(e.message)
          alert('Chyba serveru, zkuste to prosím později')
        } else if (e.response.status === 404) {
          alert('Jméno nenalezeno')
        } else if (e.response.status === 500) {
          alert('Chyba serveru, zkuste to prosím později')
        } else if (e.response.status === 403) {
          alert('špatné heslo')
        }
        window.location.reload()
      }
      this.$router.push({ name: 'Admin' })
      //window.location.reload()
    },
  },
}
</script>

<style lang="scss">
.login-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    input {
      display: block;
      //margin: 0.3em;
      font-size: 1.5em;
      resize: none;
      outline: none;
      border: 1px solid black;
      padding: 0.3em;
      width: 100%;
      margin: 0.5em;
    }

    .submit {
      padding: 0 2em;
      border: 1px solid black;
      min-width: 4em;
      min-height: 3.9em;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1em;
      &:hover {
        background-color: black;
        color: white;
        .spin-icon {
          fill: white;
          //color: rgb(238, 179, 240);
        }
      }
    }
  }
}
</style>
